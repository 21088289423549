import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Services = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="Servicios Drupal especializados | asdloop" description="Consulta todos los servicios Drupal especializados que ofrecemos para empresas. Desarrollo a medida, migraciones, consultoría y formación." />
  <section className="jumbotron bg-transparent pb-6" id="header">
    <div className="container">
      <div className="row align-items-center text-center">
        <div className="col">
          <h1 className="font-weight-bold display-4 pl-0 p-1 col-md-8 offset-md-2 col-sm-12">Solucionamos problemas tecnológicos con <span
            className="text-primary">Drupal</span></h1>
          <p className="lead mb-4 col-md-8 offset-md-2 col-sm-12">La versatilidad de Drupal lo hace uno de los gestores de contenidos más potentes del mercado. Construye con nosotros tu tienda online, tu intranet o tu web corporativa.</p>
        </div>
      </div>
    </div>
  </section>
  <section className="jumbotron pb-3 pt-5 bg-light" id="features">
    <div className="container px-md-1 px-4">
      <div className="row">
        <div className="col-md-5 pb-5">
          <h2>
            <strong>Desarrollos Drupal a medida</strong>
          </h2>
          <p className="lead">Realizamos desarrollos a medida en base a tus necesidades y la de tu negocio. Integramos tu web con los servicios que necesites: tu CRM, pasarela de pago o empresa de mensajería.</p>
          <p className="lead">Nuestros desarrollos siguen los estándares de calidad fijados por Drupal, asegurando así la calidad, escalabilidad y mantenimiento del proyecto.</p>
          {/*<a className="btn mr-4 btn-lg btn-outline-primary" href="/servicios/desarrollo-drupal-a-medida">Ver Detalles →</a>*/}
        </div>
        <div className="col-md-5 offset-md-2 pt-0 pb-5">
          <h2>
            <strong>Migraciones a Drupal 8</strong>
          </h2>
          <p className="lead">Migramos tu sitio Drupal a la última versión de Drupal, manteniendo todos tus datos y funcionalidades existentes. Es un buen momento para hacer los retoques que necesites en la funcionalidad, diseño o datos actuales.</p>
          <p className="lead">Estar actualizado garantiza estar al día con las últimas mejoras de rendimiento y seguridad. </p>
          {/*<a className="btn mr-4 btn-lg btn-outline-primary" href="/servicios/migraciones-drupal-8">Ver Detalles →</a>*/}
        </div>
      </div>
    </div>
  </section>
  <section className="jumbotron pb-3 pt-5 bg-transparent" id="features">
    <div className="container px-md-1 px-4">
      <div className="row">
        <div className="col-md-5 pb-5">
          <h2>
            <strong>Arquitectura y consultoría Drupal</strong>
          </h2>
          <p className="lead">Antes de comenzar con tu proyecto Drupal, definir una buena arquitectura es clave para el éxito del mismo. Nosotros te asesoramos en cualquier punto del proyecto para asegurarnos que estás adoptando las mejores pŕacticas. </p><p className="lead">También te ayudamos a preparar propuestas técnicas y RFPs.</p>
          {/*<a className="btn mr-4 btn-lg btn-outline-primary" href="/servicios/consultoria-drupal">Ver Detalles →</a>*/}
        </div>
        <div className="col-md-5 offset-md-2 pt-0 pb-5">
          <h2>
            <strong>Formación Drupal</strong>
          </h2>
          <p className="lead">Impartimos formación Drupal a medida para todo tipo de niveles y equipos. Diseñamos cursos tanto para principiantes, para desarrolladores con experiencia que quieren profundizar en Drupal, así como cursos para gestores de contenidos.</p>
          <p className="lead">Podemos impartir los cursos de forma presencial y remota.</p>
          {/*<a className="btn mr-4 btn-lg btn-outline-primary" href="/servicios/formacion-drupal">Ver Detalles →</a>*/}
        </div>
      </div>
    </div>
  </section>
  <section className="jumbotron pb-3 pt-5 bg-light" id="features">
    <div className="container px-md-1 px-4">
      <div className="row">
        <div className="col-md-5 pb-5">
          <h2>
            <strong>Drupal Headless &amp; Jamstack</strong>
          </h2>
          <p className="lead">En algunos casos es interesante utilizar Drupal como backend API y servir contenidos a distintas fuentes: una APP móvil, un generador de sitios estáticos o un front-end desarrollado en el framework Javascript más popular del momento.</p>
          {/*<a className="btn mr-4 btn-lg btn-outline-primary" href="/servicios/migraciones-drupal-8">Ver Detalles →</a>*/}
        </div>
        <div className="col-md-5 offset-md-2 pt-0 pb-5">
          <h2>
            <strong>Mantenimiento y Hosting</strong>
          </h2>
          <p className="lead">Una vez tu sitio web esté lanzado, te ofrecemos un servicio de mantenimiento con actualizaciones periódicas de seguridad, optimización de Drupal, copias de seguridad, soporte a consultas y resolución técnica de incidencias.</p>
          <p className="lead">Disponemos de servidores dedicados para nuestros clientes, pero podemos alojar tu sitio en tus servidores, así como implentar un flujo de CI/CD para tu equipo.</p>
          {/*<a className="btn mr-4 btn-lg btn-outline-primary" href="/servicios/mantenimiento-hosting-drupal">Ver Detalles →</a>*/}
        </div>
      </div>
    </div>
  </section>
  <section className="jumbotron pb-3 pt-5 bg-transparent" id="features">
    <div className="container px-md-1 px-4">
      <div className="row">
        <div className="col-md-5 pb-5">
          <h2>
            <strong>SEO y SEM</strong>
          </h2>
          <p className="lead">El trabajo de SEO y SEM te ayudará con el posicionamiento en buscadores y campañas de publicidad de tu proyecto (Google ads, Facebook/Instagram ads). Nuestro equipo está certificado en Google Ads (tráfico de búsqueda, móvil y shopping) y en Google Analytics.</p>
          {/*<a className="btn mr-4 btn-lg btn-outline-primary" href="/servicios/seo-sem">Ver Detalles →</a>*/}
        </div>
        <div className="col-md-5 offset-md-2 pt-0 pb-5">
          <h2>
            <strong>Diseño y UX</strong>
          </h2>
          <p className="lead">Con nuestro servicio de Diseño y UX, nos encargaremos de darle a tu proyecto el diseño visual y experiencia de usuario que necesita.</p>
          {/*<a className="btn mr-4 btn-lg btn-outline-primary" href="/servicios/diseno-ux">Ver Detalles →</a>*/}
        </div>
      </div>
    </div>
  </section>

    </Layout>
  )
}

export default Services
